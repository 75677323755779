import {APP_INITIALIZER, ApplicationConfig, importProvidersFrom, PLATFORM_ID, provideZoneChangeDetection} from '@angular/core';
import {provideRouter, withInMemoryScrolling} from '@angular/router';
import {provideClientHydration} from '@angular/platform-browser';
import {HTTP_INTERCEPTORS, HttpClientModule, provideHttpClient} from '@angular/common/http';
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {appRoutesPL} from "./app.routes";
import {CustomTranslateLoader} from "./custom-loader.service";
import {NgxPermissionsModule} from "ngx-permissions";
import {TokenInterceptor} from "./interceptors/token.interceptor";
import {AuthenticationService} from "./services/authentication.service";
import {isPlatformBrowser} from "@angular/common";
import {MarkdownModule} from "ngx-markdown";

export function initApp(authService: AuthenticationService, platformId: Object) {
    return () => isPlatformBrowser(platformId) ? authService.initializeAuthStatus() : () => {
    };
}

export const appConfig: ApplicationConfig = {
    providers: [
        provideZoneChangeDetection({eventCoalescing: true}),
        provideRouter(
            appRoutesPL,
            withInMemoryScrolling({
                scrollPositionRestoration: 'top',
                anchorScrolling: 'enabled',
            })
        ),
        provideClientHydration(),
        provideHttpClient(),
        importProvidersFrom(
            HttpClientModule,
            TranslateModule.forRoot({
                loader: {
                    provide: TranslateLoader,
                    useClass: CustomTranslateLoader,
                },
            }),
            NgxPermissionsModule.forRoot(),
            MarkdownModule.forRoot(),
        ),
        {
            provide: HTTP_INTERCEPTORS,
            useClass: TokenInterceptor,
            multi: true
        },
        {
            provide: APP_INITIALIZER,
            useFactory: initApp,
            deps: [AuthenticationService, PLATFORM_ID],
            multi: true
        },
    ],
};

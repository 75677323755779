import {Component, ElementRef, HostListener, Inject, OnInit, PLATFORM_ID, ViewChild} from '@angular/core';
import {isPlatformBrowser, NgClass, NgForOf, NgIf, NgOptimizedImage} from '@angular/common';
import {AuthenticationService} from '../../services/authentication.service';
import {Subscription} from 'rxjs';
import {environment} from '../../environments/environment';
import {Router, RouterLink} from '@angular/router';

@Component({
    selector: 'app-header',
    standalone: true,
    templateUrl: './header.component.html',
    imports: [RouterLink, NgOptimizedImage, NgClass, NgForOf, NgIf],
    styleUrls: ['./header.component.css'],
})
export class HeaderComponent implements OnInit {
    mainMenuItems: any[] = [];
    authItems: any[] = [];
    isLoggedIn = false;
    authSubscription!: Subscription;
    isBrowser: boolean = false;
    isMenuOpen = false;

    protected readonly environment = environment;

    @ViewChild('menuContent', {static: false}) menuContent!: ElementRef;
    @ViewChild('menuToggle', {static: false}) menuToggle!: ElementRef;

    constructor(
        private authService: AuthenticationService,
        private router: Router,
        @Inject(PLATFORM_ID) private platformId: Object
    ) {
        this.isBrowser = isPlatformBrowser(platformId);
    }

    ngOnInit() {
        this.mainMenuItems = [
            {label: 'Strona Główna', routerLink: '/', matchRoutes: ['/']},
            {label: 'Pytanie do Tarota', routerLink: '/tarot', matchRoutes: ['/tarot']},
            {label: 'Horoskop', routerLink: '/horoskop', matchRoutes: ['/horoskop']},
            {label: 'Blog', routerLink: '/blog', matchRoutes: ['/blog', '/blog']},
        ];

        this.authSubscription = this.authService.authStatus$.subscribe((status) => {
            this.isLoggedIn = status.isLoggedIn;

            this.authItems = this.isLoggedIn
                ? [
                    {
                        label: 'Moje Konto',
                        icon: 'pi pi-user',
                        routerLink: '/moje-konto',
                        styleClass: 'auth-item',
                        matchRoutes: ['/moje-konto'],
                    },
                    {
                        label: 'Wyloguj',
                        icon: 'pi pi-sign-out',
                        routerLink: '/wyloguj',
                        matchRoutes: ['/wyloguj'],
                    },
                ]
                : [
                    {
                        label: 'Logowanie',
                        icon: 'pi pi-sign-in',
                        routerLink: '/logowanie',
                        styleClass: 'auth-item',
                        matchRoutes: ['/logowanie'],
                    },
                    {
                        label: 'Rejestracja',
                        icon: 'pi pi-user-plus',
                        routerLink: '/rejestracja',
                        matchRoutes: ['/rejestracja'],
                    },
                ];
        });
    }

    isActive(item: any): boolean {
        if (item.routerLink === '/') {
            return this.router.url === '/';
        } else {
            return item.matchRoutes.some((route: string) => this.router.url.startsWith(route));
        }
    }

    toggleMenu() {
        this.isMenuOpen = !this.isMenuOpen;
    }

    @HostListener('document:click', ['$event'])
    onDocumentClick(event: Event): void {
        const target = event.target as HTMLElement;
        if (
            this.isMenuOpen &&
            !this.menuContent.nativeElement.contains(target) &&
            !this.menuToggle.nativeElement.contains(target)
        ) {
            this.isMenuOpen = false;
        }
    }

    closeMenu() {
        if (this.isMenuOpen) {
            this.isMenuOpen = false;
        }
    }
}

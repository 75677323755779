import {inject} from '@angular/core';
import {ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree} from '@angular/router';
import {AuthenticationService} from "../services/authentication.service";
import {NgxPermissionsService} from "ngx-permissions";
import {from, Observable, of} from 'rxjs';
import {catchError, map, switchMap} from 'rxjs/operators';

export function roleGuard(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const authService = inject(AuthenticationService);
    const permissionsService = inject(NgxPermissionsService);
    const router = inject(Router);

    return authService.authStatus$.pipe(
        switchMap(isLoggedIn => {
            if (!isLoggedIn) {
                return of(redirectToLogin(state.url, router));
            }

            const requiredRoles = next.data['roles'] as Array<string>;

            return requiredRoles?.length
                ? checkPermissions(requiredRoles, permissionsService, router)
                : of(true);
        }),
        catchError(() => of(redirectToLogin(state.url, router)))
    );
}

function redirectToLogin(returnUrl: string, router: Router): UrlTree {
    return router.parseUrl('/' + returnUrl);
}

function redirectToDefaultPage(router: Router): UrlTree {
    return router.parseUrl('/');
}

function checkPermissions(requiredRoles: Array<string>,
                          permissionsService: NgxPermissionsService,
                          router: Router): Observable<boolean | UrlTree> {
    return from(permissionsService.hasPermission(requiredRoles)).pipe(
        map(hasPermission => hasPermission || redirectToDefaultPage(router))
    );
}

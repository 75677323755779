import {Component, OnInit} from '@angular/core';
import {SharedModule} from "primeng/api";
import {ToolbarModule} from "primeng/toolbar";
import {ButtonModule} from "primeng/button";
import {RouterLink} from "@angular/router";
import {NgForOf, NgIf, NgOptimizedImage} from "@angular/common";
import {environment} from "../../environments/environment";

@Component({
    selector: 'app-footer',
    standalone: true,
    imports: [
        SharedModule,
        ToolbarModule,
        ButtonModule,
        RouterLink,
        NgOptimizedImage,
        NgIf,
        NgForOf
    ],
    templateUrl: './footer.component.html',
    styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {
    footerText!: string;
    logoSrc!: string;
    socialLinks: { href: string, label: string, icon: string }[] = [];

    ngOnInit() {
        this.footerText = '© 2024 ' + environment.websiteName;
        this.logoSrc = environment.websiteLogo;
        this.socialLinks = [
            {href: environment.tikTokUrl, label: 'TikTok', icon: 'pi pi-tiktok'},
            {href: environment.youtubeUrl, label: 'YouTube', icon: 'pi pi-youtube'},
            {href: environment.instagramUrl, label: 'Instagram', icon: 'pi pi-instagram'},
            {href: environment.facebookUrl, label: 'Facebook', icon: 'pi pi-facebook'}
        ];
    }
}
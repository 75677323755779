import {Component, OnInit} from "@angular/core";
import {NgClass, NgSwitch, NgSwitchCase} from "@angular/common";
import {HeaderComponent} from "./layout/header/header.component";
import {NavigationEnd, Router, RouterOutlet} from "@angular/router";
import {FooterComponent} from "./layout/footer/footer.component";
import {ToastModule} from "primeng/toast";
import {MessageService, PrimeNGConfig} from "primeng/api";
import {SeoService} from "./services/seo.service";
import {TranslateService} from "@ngx-translate/core";
import {environment} from "./environments/environment";
import {filter} from "rxjs/operators";

declare let gtag: Function;

@Component({
    selector: 'app-root',
    standalone: true,
    templateUrl: './app.component.html',
    imports: [
        NgSwitch,
        NgSwitchCase,
        HeaderComponent,
        RouterOutlet,
        NgClass,
        FooterComponent,
        ToastModule,
    ],
    providers: [MessageService],
    styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
    constructor(private primengConfig: PrimeNGConfig,
                private router: Router,
                private seoService: SeoService,
                private translate: TranslateService) {
        translate.setDefaultLang(environment.defaultLanguage);
        translate.use(environment.defaultLanguage);
    }

    ngOnInit() {
        this.setSeoParams();
        this.primengConfig.ripple = false;
        this.setupGoogleAnalyticsTracking();
    }

    isHomepage(): boolean {
        return this.router.url === '/' || this.router.url === '';
    }

    private setupGoogleAnalyticsTracking() {
        this.router.events.pipe(
            filter(event => event instanceof NavigationEnd)
        ).subscribe((event: NavigationEnd) => {
            this.trackPageViewWithGoogleAnalytics(event.urlAfterRedirects);
        });
    }

    private trackPageViewWithGoogleAnalytics(pagePath: string) {
        if (typeof gtag === 'function') {
            gtag('config', environment.googleAnalyticsId, {'page_path': pagePath});
        }
    }

    private setSeoParams() {
        this.translate.get(['appComponent.seoTitle', 'appComponent.seoDescription', 'appComponent.seoKeywords']).subscribe(translations => {
            this.seoService
                .updateTitle(translations['appComponent.seoTitle'])
                .updateDescription(translations['appComponent.seoDescription'])
                .updateKeywords(translations['appComponent.seoKeywords'])
                .updateOgImage()
                .updateOgUrl()
                .updateOgType()
                .updateLanguage();
        });
    }
}

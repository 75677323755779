import {TranslateLoader} from '@ngx-translate/core';
import {Observable, of} from 'rxjs';

const translationsPL = {
    "header": {
        "menu": {
            "home": "Strona Główna",
            "askToTarot": "Pytanie do Tarota",
            "dailyHoroscope": "Horoskop Dzienny",
            "blog": "Blog"
        },
        "routes": {
            "home": "/",
            "askToTarot": "/tarot/pytanie-do-tarota",
            "dailyHoroscope": "/horoskop/dzienny",
            "blog": "/blog"
        },
        "breadcrumb": {
            "home": "Strona główna",
            "dashboard": "Dashboard",
            "bookmarks": "Zakładki",
            "blog": "Blog",
            "partnerPanel": "Panel partnera",
            "login": "Logowanie",
            "register": "Rejestracja"
        }
    },
    "appComponent": {
        "seoTitle": "Czytanie Kart Tarota Online - Natychmiastowa Odpowiedź",
        "seoDescription": "Zadaj pytanie kartom tarota i uzyskaj natychmiastową odpowiedź. Czytanie tarota dostępne o każdej porze dnia i nocy. Sprawdź, co karty mają do powiedzenia.",
        "seoKeywords": "pytanie do kart tarota, tarot online, karty do tarota, rozkład kart tarota, interpretacje kart tarota, horoskop z kart tarota, symbolika tarota, czytanie tarota online, pytanie do tarota, interpretacje tarota, czytanie tarota 24/7"
    },
    "homePageComponent": {
        "seoTitle": "Czytanie Kart Tarota Online - Natychmiastowa Odpowiedź",
        "seoDescription": "Zadaj pytanie kartom tarota i uzyskaj natychmiastową odpowiedź. Czytanie tarota dostępne o każdej porze dnia i nocy. Sprawdź, co karty mają do powiedzenia.",
        "seoKeywords": "pytanie do kart tarota, tarot online, karty do tarota, rozkład kart tarota, interpretacje kart tarota, horoskop z kart tarota, symbolika tarota, czytanie tarota online, pytanie do tarota, interpretacje tarota, czytanie tarota 24/7",
        "title": "Czytanie Tarota Online",
        "subtitle": "Zadaj pytanie, wylosuj karty, a nasz system natychmiast odpowie na Twoje pytanie na podstawie rozkładu tarota.",
        "subsubtitle": "O każdej porze dnia lub nocy.",
        "ctaButton": "Zadaj pytanie",
        "sectionTitle": "Poznaj świat tarota na nowo – bez uprzedzeń, bez nadinterpretacji, po prostu czysta symbolika i przesłanie kart.",
        "sectionContent": "Na SzybkiTarot.pl łączymy starożytną sztukę tarota z nowoczesnymi technologiami, aby dostarczyć Ci unikalne doświadczenie. Nasz system, w oparciu o sztuczną inteligencję, wolną od ludzkich emocji i subiektywnych opinii, analizuje karty w sposób, który jest neutralny i skupia się wyłącznie na przesłaniach zawartych w symbolach.",
        "features": {
            "impartiality": {
                "title": "Bezstronność",
                "description": "Interpretacje kart tarota są pozbawione ludzkich uprzedzeń, skupiając się tylko na tym, co naprawdę pokazują karty."
            },
            "transparency": {
                "title": "Przejrzystość",
                "description": "Otrzymujesz jasną i zrozumiałą interpretację, która opiera się na tradycyjnej symbolice tarota, analizowanej przez nasz system komputerowy."
            },
            "easeOfUse": {
                "title": "Łatwość Użycia",
                "description": "W kilka chwil uzyskasz klarowną analizę swojego rozkładu kart, bez zbędnych komplikacji."
            }
        },
        "finalContent": "Zapraszamy do odkrycia, co karty mają Ci do przekazania. Nasz system komputerowy, w oparciu o sztuczną inteligencję, jako neutralny przewodnik, pomoże Ci zrozumieć przesłania, jakie niesie ze sobą tarot. Wejdź na nowy poziom samopoznania i przekonaj się, jak bardzo może Cię zaskoczyć tarot bez uprzedzeń.",
        "ctaStart": "Zadaj pytanie",
        "paths": {
            "askToTarot": "/tarot/pytanie-do-tarota"
        }
    },
    "blogList": {
        "title": "Blog Ezoteryczny",
        "subtitle": "Przeglądaj artykuły z naszego bloga, pełne ezoterycznych porad i duchowych inspiracji.",
        "readMore": "Czytaj więcej",
        "seo": {
            "title": "Ezoteryczny Blog - Porady i Duchowe Inspiracje",
            "description": "Poznaj nasz blog ezoteryczny pełen tajemnic duchowego świata. Odkryj porady duchowe i mistyczne inspiracje. Zanurz się w magii tarota i innych praktyk.",
            "keywords": "ezoteryka, blog ezoteryczny, porady duchowe, tarot, artykuły o tarocie, duchowe inspiracje, mistyczne porady, duchowość"
        }
    },
    "zodiac": {
        "baran": "Baran",
        "byk": "Byk",
        "bliznieta": "Bliźnięta",
        "rak": "Rak",
        "lew": "Lew",
        "panna": "Panna",
        "waga": "Waga",
        "skorpion": "Skorpion",
        "strzelec": "Strzelec",
        "koziorozec": "Koziorożec",
        "wodnik": "Wodnik",
        "ryby": "Ryby",
        "zodiacWheelAlt": "Koło zodiaku"
    },
    "dailyHoroscopeComponent": {
        "menu": {
            "dailyHoroscope": "Horoskop dzienny",
            "partnerHoroscope": "Horoskop partnerski"
        },
        "paths": {
            "dailyHoroscope": "/horoskop/dzienny",
            "partnerHoroscope": "/horoskop/partnerski"
        },
        "title": "Horoskop dzienny | {{currentDate}}",
        "intro": "Poznaj, co dzisiejszy horoskop przygotował dla Ciebie. Wybierz swój znak zodiaku, aby odkryć prognozy na dziś.",
        "description": "Astrologia ma długą i fascynującą historię, sięgającą czasów starożytnych, kiedy to służyła jako naukowe narzędzie do badania wpływu planet na życie ludzi. Nawet tak wybitni naukowcy jak Johannes Kepler korzystali z jej odkryć. Choć dziś jest traktowana z dystansem, wciąż pozostaje cennym źródłem wiedzy o naszych cechach charakteru i relacjach międzyludzkich. Na podstawie układu planet tworzy się tzw. kosmogram, który pozwala odkryć nasz znak zodiaku, ascendent oraz inne kluczowe elementy. Horoskopy można odczytywać samodzielnie, kierując się wiedzą o swoim znaku, lub skonsultować się z astrologiem, aby uzyskać bardziej precyzyjną analizę.",
        "errors": {
            "fetchingHoroscope": "Błąd podczas pobierania horoskopu."
        },
        "seo": {
            "title": "Horoskop dzienny dla {{zodiacSign}} - {{currentDate}}",
            "description": "Horoskop dzienny ({{currentDate}}) dla znaku {{zodiacSign}}. Zobacz, jakie prognozy przygotowały dla Ciebie gwiazdy i astrologia. Codzienne porady i wskazówki astrologiczne.",
            "keywords": "{{zodiacSign}}, horoskop {{zodiacSign}}, dzienny horoskop {{zodiacSign}}, horoskop, astrologia, znak zodiaku {{zodiacSign}}, przyszłość {{zodiacSign}}, dzisiejszy horoskop {{zodiacSign}}",
            "mainTitle": "Horoskop dzienny - {{currentDate}}",
            "mainDescription": "Sprawdź swój horoskop dzienny na {{currentDate}}. Wybierz swój znak zodiaku i dowiedz się, co przyniesie Ci dzień.",
            "mainKeywords": "horoskop dzienny, horoskop, astrologia, znaki zodiaku, przyszłość"
        }
    },
    "partnerHoroscopeComponent": {
        "menu": {
            "dailyHoroscope": "Horoskop dzienny",
            "partnerHoroscope": "Horoskop partnerski"
        },
        "paths": {
            "dailyHoroscope": "/horoskop/dzienny",
            "partnerHoroscope": "/horoskop/partnerski"
        },
        "title": "Horoskop dzienny partnerski | {{currentDate}}",
        "intro": "Poznaj, co dzisiejszy horoskop partnerski przygotował dla Was. Wybierzcie swoje znaki zodiaku, aby odkryć prognozy na dziś dotyczące Waszej relacji.",
        "description": "Astrologia partnerska to dziedzina, która od wieków pomaga zrozumieć, jak różne znaki zodiaku oddziałują na siebie w związkach. Od starożytności po czasy współczesne, ludzie korzystali z tej wiedzy, aby lepiej poznać naturę swoich relacji. Układ planet w momencie naszego urodzenia wpływa nie tylko na to, kim jesteśmy, ale również na to, jak wchodzimy w interakcje z innymi. Znaki zodiaku mogą wskazać, gdzie Wasza para znajdzie najwięcej harmonii, a w jakich sytuacjach mogą pojawić się wyzwania. Dzięki horoskopowi dla par odkryjecie, jakie aspekty waszego związku są szczególnie silne, a nad którymi warto popracować, by stworzyć głęboką i trwałą relację. To nie tylko zabawa, ale też sposób na lepsze zrozumienie siebie nawzajem i budowanie pełniejszego, bardziej świadomego związku.",
        "labels": {
            "firstSign": "Pierwszy znak",
            "secondSign": "Drugi znak",
            "display": "Wyświetl"
        },
        "errors": {
            "fetchingHoroscope": "Błąd podczas pobierania horoskopu.",
            "selectBothSigns": "Proszę wybrać oba znaki zodiaku."
        },
        "seo": {
            "title": "Dzienny Horoskop Partnerski dla {{firstSign}} i {{secondSign}} - {{currentDate}}",
            "description": "Dzienny Horoskop Partnerski ({{currentDate}}) dla znaków {{firstSign}} i {{secondSign}}. Sprawdź dzisiejsze astrologiczne prognozy i porady dla waszej relacji.",
            "keywords": "horoskop partnerski, {{firstSign}} i {{secondSign}}, horoskop dzienny dla par, horoskop {{firstSign}} {{secondSign}}, astrologia, relacje zodiakalne, miłość {{firstSign}} {{secondSign}}, horoskop miłosny",
            "mainTitle": "Horoskop dzienny partnerski - {{currentDate}}",
            "mainDescription": "Sprawdź swój dzienny horoskop partnerski na {{currentDate}}. Wybierz znaki zodiaku, aby dowiedzieć się, co gwiazdy mówią o Waszej relacji.",
            "mainKeywords": "horoskop partnerski, horoskop dzienny, astrologia, znaki zodiaku, relacje, miłość"
        }
    },
    "tarotDeckComponent": {
        "questionSection": {
            "title": "Zadaj pytanie",
            "placeholder": "Podziel się swoim pytaniem lub wątpliwością. Co Cię nurtuje? Zadaj pytanie, a karty tarota odkryją odpowiedź specjalnie dla Ciebie.",
            "errors": {
                "minlength": "Pytanie musi mieć co najmniej 10 znaków.",
                "maxlength": "Pytanie nie może być dłuższe niż 500 znaków.",
                "pattern": "Pytanie może zawierać tylko litery, cyfry i znaki interpunkcyjne."
            }
        },
        "selectLayout": {
            "label": "Wybierz układ kart",
            "options": {
                "1Cards": "1 karta",
                "3Cards": "3 karty",
                "5Cards": "5 kart",
                "7Cards": "7 kart",
                "10Cards": "Krzyż celtycki (10 kart)"
            }
        },
        "submitButton": {
            "label": "Uzyskaj odpowiedź",
            "disclaimer": "Klikając przycisk ‘Uzyskaj odpowiedź’, akceptujesz, że odpowiedź jest generowana przez system komputerowy i ma charakter rozrywkowy. Nie stanowi profesjonalnej porady."
        },
        "shareableLinkPath": "/wrozba-tarota/",
        "interpretationSection": {
            "loadingTitle": "Interpretacja Twojego pytania",
            "newQuestionButton": "Zadaj nowe pytanie"
        },
        "cardMeaningsSection": {
            "title": "Znaczenie wybranych kart",
            "cardAltText": "Tarot Card"
        },
        "seo": {
            "title": "Czytanie Kart Tarota Online - Natychmiastowa Odpowiedź",
            "description": "Zadaj pytanie kartom tarota i uzyskaj natychmiastową odpowiedź. Czytanie tarota dostępne o każdej porze dnia i nocy. Sprawdź, co karty mają do powiedzenia.",
            "keywords": "pytanie do kart tarota, tarot online, karty do tarota, rozkład kart tarota, interpretacje kart tarota, horoskop z kart tarota, symbolika tarota, czytanie tarota online, pytanie do tarota, interpretacje tarota, czytanie tarota 24/7"
        }
    }
}

export class CustomTranslateLoader implements TranslateLoader {
    getTranslation(lang: string): Observable<any> {
        return of(translationsPL);
    }
}

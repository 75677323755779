<div class="menu-container surface-overlay">
    <div class="menu-content" #menuContent>
        <a [routerLink]="['/']" class="logo-link">
            <img [ngSrc]="environment.websiteLogo" alt="SzybkiTarot.pl" height="35" width="225" class="logo"/>
        </a>
        <button class="menu-toggle" (click)="toggleMenu()" #menuToggle>
            <i class="pi pi-bars"></i>
        </button>
        <nav class="main-nav" [ngClass]="{ 'active': isMenuOpen }">
            <ul class="menu-items">
                <li *ngFor="let item of mainMenuItems" [ngClass]="item.styleClass">
                    <a [routerLink]="item.routerLink" (click)="closeMenu()" [ngClass]="{ 'active': isActive(item) }">
                        <i *ngIf="item.icon" class="{{ item.icon }}"></i>
                        {{ item.label }}
                    </a>
                </li>
            </ul>
            <ul class="auth-items">
                <li *ngFor="let item of authItems" [ngClass]="item.styleClass">
                    <a [routerLink]="item.routerLink" (click)="closeMenu()" [ngClass]="{ 'active': isActive(item) }">
                        <i *ngIf="item.icon" class="{{ item.icon }}"></i>
                        {{ item.label }}
                    </a>
                </li>
            </ul>
        </nav>
    </div>
</div>

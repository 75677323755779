<div class="surface-section px-4 pb-8 md:px-6 lg:px-8" *ngIf="logoSrc && footerText">
    <div class="border-top-1 border-300 pt-5 text-center">
        <img [ngSrc]="logoSrc" alt="Logo" height="35" width="225">

        <div class="my-4">
            <a [routerLink]="'/regulamin'" class="mr-3">Regulamin</a>
            <a [routerLink]="'/polityka-prywatnosci'">Polityka Prywatności</a>
        </div>

        <div class="social-icons p-0 mx-0 my-5 flex justify-content-center align-items-center lg:flex-row">
            <a *ngFor="let link of socialLinks" [href]="link.href" target="_blank" [attr.aria-label]="link.label">
                <i [class]="link.icon"></i>
            </a>
        </div>

        <div class="p-0 mx-0 my-5 flex justify-content-center flex-column align-items-center lg:flex-row">
            <span class="text-600 line-height-3 lg:mr-5">{{ footerText }}</span>
        </div>
    </div>
</div>
import {Injectable} from '@angular/core';
import {HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Observable, throwError} from 'rxjs';
import {CsrfTokenService} from "../services/csrf-token.service";
import {catchError} from 'rxjs/operators';
import {AuthenticationService} from "../services/authentication.service";
import {Router} from "@angular/router";

@Injectable()
export class TokenInterceptor implements HttpInterceptor {

    constructor(private csrfTokenService: CsrfTokenService,
                private authService: AuthenticationService,
                private router: Router) {
    }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        if (['POST', 'PUT', 'DELETE'].includes(request.method)) {
            const csrfToken = this.csrfTokenService.getCsrfTokenFromStorage();
            if (csrfToken) {
                request = request.clone({setHeaders: {'X-CSRF-TOKEN': csrfToken}});
            }
        }

        return next.handle(request).pipe(
            catchError((error: HttpErrorResponse) => {
                if (error.error && error.error.errorCode === 'CSRF_TOKEN_INVALID') {
                    this.authService.clearSession();
                    this.router.navigate(['/logowanie']);
                }

                return throwError(() => error);
            })
        );
    }
}

import {Routes} from '@angular/router';
import {roleGuard} from "./guards/role.guard";

export const appRoutesPL: Routes = [
    {
        path: '',
        loadChildren: () => import('./pages/home/home.routes').then((m) => m.homeRoutes),
    },
    {
        path: 'blog',
        loadComponent: () => import('./pages/blog/blog-wrapper/blog.component').then((m) => m.BlogComponent),
        children: [
            {
                path: '',
                loadComponent: () => import('./pages/blog/blog-list/blog-list.component').then((m) => m.BlogListComponent),
            },
            {
                path: ':slugTitle',
                loadComponent: () => import('./pages/blog/article-detail/article-detail.component').then((m) => m.ArticleDetailComponent),
            },
            {
                path: 'pytanie/:slugTitle',
                loadComponent: () => import('./pages/blog/tarot-reading/tarot-reading.component').then((m) => m.TarotReadingComponent),
            },
        ],
    },
    {
        path: 'horoskop',
        loadComponent: () => import('./pages/./horoscope-pages/wrapper/horoscope-wrapper.component').then((m) => m.HoroscopeWrapperComponent),
        children: [
            {
                path: '',
                redirectTo: 'dzienny',
                pathMatch: 'full'
            },
            {
                path: 'dzienny',
                loadComponent: () => import('./pages/./horoscope-pages/daily-horoscope/daily-horoscope.component').then((m) => m.DailyHoroscopeComponent),
            },
            {
                path: 'dzienny/:sign',
                loadComponent: () => import('./pages/./horoscope-pages/daily-horoscope/daily-horoscope.component').then((m) => m.DailyHoroscopeComponent),
            },
            {
                path: 'partnerski',
                loadComponent: () => import('./pages/./horoscope-pages/partner-horoscope/partner-horoscope.component').then((m) => m.PartnerHoroscopeComponent),
            },
            {
                path: 'partnerski/:firstSign/:secondSign',
                loadComponent: () => import('./pages/./horoscope-pages/partner-horoscope/partner-horoscope.component').then((m) => m.PartnerHoroscopeComponent),
            }
        ],
    },
    {
        path: 'tarot',
        loadComponent: () => import('./pages/tarot-pages/wrapper/tarot-wrapper.component').then((m) => m.TarotWrapperComponent),
        children: [
            {
                path: '',
                redirectTo: 'pytanie-do-tarota',
                pathMatch: 'full'
            },
            {
                path: 'pytanie-do-tarota',
                loadComponent: () => import('./pages/tarot-pages/pytanie-do-tarota/pytanie-do-tarota.component').then((m) => m.PytanieDoTarotaComponent),
            },
            {
                path: 'pakiet-pytan',
                loadComponent: () => import('./pages/tarot-pages/nielimitowany-tarot/nielimitowany-tarot.component').then((m) => m.NielimitowanyTarotComponent),
            },
            {
                path: 'losuj-karty-tarota',
                loadComponent: () => import('./pages/tarot-pages/losuj-karty-tarota/losuj-karty-tarota.component').then((m) => m.LosujKartyTarotaComponent),
            }
        ],
    },
    {
        path: 'logowanie',
        loadComponent: () => import('./pages/login-registartion/login/login.component').then((m) => m.LoginComponent),
    },
    {
        path: 'rejestracja',
        loadComponent: () => import('./pages/login-registartion/register/register.component').then((m) => m.RegisterComponent),
    },
    {
        path: 'wyloguj',
        loadComponent: () => import('./pages/login-registartion/logout/logout.component').then((m) => m.LogoutComponent),
    },
    {
        path: 'przypomnij-haslo',
        loadComponent: () => import('./pages/login-registartion/forgot-password/forgot-password.component').then((m) => m.ForgotPasswordComponent),
    },
    {
        path: 'reset-hasla',
        loadComponent: () => import('./pages/login-registartion/forgot-password/reset-password.component').then((m) => m.ResetPasswordComponent),
    },
    {
        path: 'moje-konto',
        loadComponent: () => import('./pages/myaccount/myaccount-wrapper/my-account.component').then((m) => m.MyAccountComponent),
        canActivate: [roleGuard],
        children: [
            {path: '', redirectTo: 'dane-konta', pathMatch: 'full'},
            {
                path: 'dane-konta',
                loadComponent: () => import('./pages/myaccount/account-data/account-data.component').then((m) => m.AccountDataComponent),
            },
            {
                path: 'historia-zakupow',
                loadComponent: () => import('./pages/myaccount/purchase-history/purchase-history.component').then((m) => m.PurchaseHistoryComponent),
            },
            {
                path: 'zmiana-hasla',
                loadComponent: () => import('./pages/myaccount/change-password/change-password.component').then((m) => m.ChangePasswordComponent),
            },
            {
                path: 'preferencje',
                loadComponent: () => import('./pages/myaccount/preferences/preferences.component').then((m) => m.PreferencesComponent),
            },
        ],
    },
    {
        path: 'odpowiedz-tarota/:uuid',
        loadComponent: () => import('./pages/tarot-reading-by-uuid/tarot-reading.component').then((m) => m.TarotReadingComponent),
    },
    {
        path: 'platnosc-sukces',
        loadComponent: () => import('./pages/purchase-offer/purchase-success.component').then((m) => m.PurchaseSuccessComponent),
    },
    {
        path: 'platnosc-blad',
        loadComponent: () => import('./pages/purchase-offer/purchase-error.component').then((m) => m.PurchaseErrorComponent),
    },
    {
        path: 'youtube',
        loadComponent: () => import('./pages/stream-forms/yt/youtube-form.component').then((m) => m.YoutubeFormComponent),
    },

    {
        path: 'polityka-prywatnosci',
        loadComponent: () => import('./pages/documentation/privacy-policy/privacy-policy.component').then((m) => m.PrivacyPolicyComponent),
    },
    {
        path: 'regulamin',
        loadComponent: () => import('./pages/documentation/terms-of-service/terms-of-service.component').then((m) => m.TermsOfServiceComponent),
    },
    {
        path: 'healthcheck',
        loadComponent: () => import('./config/healthcheck.component').then((m) => m.HealthcheckComponent),
    },
    {
        path: '**',
        redirectTo: '',
        pathMatch: 'full',
    },
];